import axios from "axios"
import config from "../config";

const server_url = config.SERVER_URL;

const getCategories = async () => {
    const response = await axios.get(`${server_url}/categories`);
    return response?.data?.categories;
}

export {
    getCategories
}
