// module.export = {
//     SERVER_URL: process.env.enviroment === 'development' ? 'http://162.243.168.28:4444' : 'https://api.bagalkotbroker.com'
// }

let NODE_ENV = 'production';

let modes = [
    {
        key: 'buy',
        name: 'Buy',
    },
    {
        key: 'rent',
        name: 'Rent',
    },
    {
        key: 'lease',
        name: 'Lease',
    }
]

let common_config = {
    background_color: '#F0F2F5',
    primary_color: '#1E88E5',
    secondary_color: 'gray',
    modes: modes
}

let development_config = {
    ...common_config,
    SERVER_URL: 'http://localhost:3000'
}

let production_config = {
    ...common_config,
    SERVER_URL: 'https://api.bagalkotbroker.com'
}

const config = NODE_ENV === 'development' ? development_config : production_config;

export default config;