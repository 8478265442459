import { ButtonGroup, Card, Grid, Pagination } from "@mui/material";
import MDBadge from "../components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import React from "react";
import axios from "axios";

// Images
import MDButton from "components/MDButton";
import { AddCircleOutlined } from "@mui/icons-material";
import Swal from 'sweetalert2';
import config from "../config";
import { Link } from "react-router-dom";

const server_url = config.SERVER_URL;


class Ads extends React.Component {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  constructor(props) {
    super(props);
    this.state = {
      ads: [],
      columns: [
        { Header: "Category", accessor: "category", align: "left" },
        // { Header: "Sub Category", accessor: "sub_category", align: "left" },
        { Header: "Title", accessor: "title", align: "left" },
        { Header: "status", accessor: "status", align: "center" },
        { Header: "owner", accessor: "owner_name", align: "left" },
        { Header: "Phone number", accessor: "phone_number", align: "left" },
        { Header: "Views", accessor: "views", align: "center" },
        { Header: "Action", accessor: "editAction", align: "center" }
      ],
      rows: [],
      pages_count: 0,
      page: 0,
      limit: 10,
      skip: 0,
      total: 0
    };
  }

  processRow(row) {

    row['category'] = (
      <>
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.sub_category}
        </MDTypography>
        <br />
        <MDTypography variant="caption">{row.category}</MDTypography>
      </>
    );

    row['status'] = (
      <MDBox ml={-1}>
        <MDBadge badgeContent={row['status']} color={ (row["status"] === "active") ? "success" : "error" } variant="gradient" size="sm" />
      </MDBox>
    )

    row['editAction'] = (

      <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
        <Link to={"/admin/ads/create?id="+row._id}>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
          >
            Edit
          </MDButton>
        </Link>
        <MDButton
          variant="gradient"
          color="error"
          onClick={() => {
            this.deleteAd(row._id);
          }}
          size="small"
        >
          Delete
        </MDButton>
      </ButtonGroup>
    )

    return row;
  }

  processRows(rows) {
    for(let i=0; i<rows.length; i++) {
      rows[i] = this.processRow(rows[i]);
    }
    return rows;
  }

  getAds = async (limit=10, skip=0) => {
    try {

      // Swal Loading
      Swal.fire({
        title: 'Loading...',
        didOpen: () => {
          Swal.showLoading()
        }
      });

      const response = await axios.get(server_url + "/ads?limit=" + limit + "&skip=" + skip, {
        headers: {
          token: localStorage.getItem('token')
        }
      });
      let rows = response.data.Ads;
      rows = this.processRows(rows);

      let total = response.data.total ? response.data.total : rows.length;
      let pages_count = Math.ceil(total / limit);
      let page = Math.ceil(skip / limit) + 1;

      this.setState({ rows: rows, total: total, pages_count: pages_count, page: page, limit: limit, skip: skip });
      // Swal Close
      Swal.close();
      // this.Toast.fire({
      //   icon: 'success',
      //   title: 'Ads fetched successfully'
      // });
    } catch (error) {
      console.log(error);
      // alert("Error fetching Ads");
      this.Toast.fire({
        icon: 'error',
        title: 'Error fetching Ads'
      });

    }
  };

  deleteAd = async (id) => {
    try {
      const response = await axios.delete(server_url + "/ads/" + id, {
        headers: {
          token: localStorage.getItem('token')
        }
      });
      if(response.data.success) {
        alert("Ad deleted successfully");
        await this.getAds();
      }
    } catch (error) {
      console.log(error);
      alert("Error deleting Ad");
    }
  };

  async componentDidMount() {
    await this.getAds();
  }


  render() {
    const columns = this.state.columns;
    const rows = this.state.rows;
    return (
      <DashboardLayout>
        <div
          style={{
            textAlign: 'right'
          }}
        >
          <Link to="/admin/ads/create">
            <MDButton
              variant="gradient"
              color="info"
              // onClick={() => {
              //   // this.props.history.push('/admin/ads/create');
              //   window.location.href = '/admin/ads/create';
              // }}
              // fullWidth
              startIcon={<AddCircleOutlined />}
            >
              Create Ad
            </MDButton>
          </Link>
        </div>

        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Ads
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" style={{width: '100%'}}>
            <Grid item xs={12}>
              <Pagination variant="outlined" shape="rounded" size="large" count={this.state.pages_count} page={this.state.page} onChange={(e, value) => {
                this.setState({page: value});
                this.getAds(this.state.limit, (value-1)*this.state.limit);
              }} style={{margin: '10px'}} />
            </Grid>
          </Grid>

        </MDBox>
      </DashboardLayout>
    );
  }

}

export default Ads;