import { Grid, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import Card from "@mui/material/Card";
import axios from "axios";
import MDInput from "../components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Swal from 'sweetalert2';
import config from "../config";
import { getCategories } from "services/category.service";
import { getSubCategories } from "services/subCategory.service";

const server_url = config.SERVER_URL;

class CreateAd extends React.Component {

    Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            sub_categories: [],
            ad_id: null,
            existing_assets: [],

            category: "",
            sub_category: "",
            mode: [],
            title: "",
            text: "",
            additional_info: "",
            price: "",
            is_price_negotiable: false,
            address_line1: '',
            address_line2: '',
            landmark: '',
            city: '',
            taluk: '',
            district: '',
            state: '',
            country: '',
            pincode: '',
            latitude: '',
            longitude: '',
            owner_name: '',
            phone_number: '',
            email_id: '',
            is_verified: false,
            is_featured: false,
            status: true,
            assets: null
        };
    }

    getAdDetails = async (id) => {
        try {
            const response = await axios.get(server_url + "/Ads/" + id, {
                headers: {
                    token: localStorage.getItem('token')
                }
            });

            const adDetails = response.data.message;

            await this.getSubCategories(adDetails.category_key);

            this.setState({
                category: adDetails.category_key,
                sub_category: adDetails.sub_category_key,
                mode: adDetails.mode,
                title: adDetails.title,
                text: adDetails.text,
                additional_info: adDetails.additional_info,
                price: adDetails.price,
                is_price_negotiable: adDetails.is_price_negotiable,
                address_line1: adDetails.address_line1,
                address_line2: adDetails.address_line2,
                landmark: adDetails.landmark,
                city: adDetails.city,
                taluk: adDetails.taluk,
                district: adDetails.district,
                state: adDetails.state,
                country: adDetails.country,
                pincode: adDetails.pincode,
                latitude: adDetails.latitude,
                longitude: adDetails.longitude,
                owner_name: adDetails.owner_name,
                phone_number: adDetails.phone_number,
                email_id: adDetails.email_id,
                is_verified: adDetails.is_verified,
                is_featured: adDetails.is_featured,
                status: (adDetails.status === 'active') ? true : false,
                existing_assets: adDetails.assets
            })

        } catch (error) {
            console.error(error.message);
            this.Toast.fire({
                icon: 'error',
                title: 'Error fetching ad details'
            });
        }
    };


    getSubCategories = async (category) => {
        if (!category) {
            this.setState({ sub_categories: [] });
            return;
        }

        let sub_categories = await getSubCategories(category);
        if (sub_categories) {
            this.setState({ sub_categories: sub_categories });
        }
    };

    async componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');

        let categories = await getCategories();
        if (categories) {
            this.setState({ categories: categories });
        }

        if (id) {
            this.setState({ ad_id: id });
            await this.getAdDetails(id);
        }
    }

    isDataValid() {
        if (!this.state.category) {
            this.Toast.fire({
                icon: 'error',
                title: 'Please select a category'
            });
            return false
        }
        if (!this.state.sub_category) {
            this.Toast.fire({
                icon: 'error',
                title: 'Please select a sub category'
            });
            return false
        }
        if (!this.state.price) {
            this.Toast.fire({
                icon: 'error',
                title: 'Please enter a price'
            });
            return false
        }
        if (!this.state.city) {
            this.Toast.fire({
                icon: 'error',
                title: 'Please enter a city'
            });
            return false
        }
        if (!this.state.district) {
            this.Toast.fire({
                icon: 'error',
                title: 'Please enter a district'
            });
            return false
        }
        if (!this.state.state) {
            this.Toast.fire({
                icon: 'error',
                title: 'Please enter a state'
            });
            return false
        }
        if (!this.state.country) {
            this.Toast.fire({
                icon: 'error',
                title: 'Please enter a country'
            });
            return false
        }
        return true
    }

    async saveAd() {
        if (!this.isDataValid()) {
            return
        }

        let form_data = new FormData();

        const payload = {
            category_key: this.state.category,
            sub_category_key: this.state.sub_category,
            mode: this.state.mode,
            title: this.state.title,
            text: this.state.text,
            additional_info: this.state.additional_info,
            price: this.state.price,
            is_price_negotiable: this.state.is_price_negotiable,
            address_line1: this.state.address_line1,
            address_line2: this.state.address_line2,
            landmark: this.state.landmark,
            city: this.state.city,
            taluk: this.state.taluk,
            district: this.state.district,
            state: this.state.state,
            country: this.state.country,
            pincode: this.state.pincode,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            owner_name: this.state.owner_name,
            phone_number: this.state.phone_number,
            email_id: this.state.email_id,
            is_verified: this.state.is_verified,
            is_featured: this.state.is_featured,
            status: (this.state.status) ? 'active' : 'inactive'
        };

        form_data.append('body_string', JSON.stringify(payload));

        if (this.state.assets && this.state.assets.length && this.state.assets.length > 0) {
            for (let i = 0; i < this.state.assets.length; i++) {
                form_data.append('asset' + (i + 1), this.state.assets[i]);
            }
        }

        const queryParams = new URLSearchParams(window.location.search);
        const ad_id = queryParams.get('id');

        if (ad_id) {
            try {
                let response = await axios.patch(server_url + '/ads/' + ad_id, form_data, {
                    headers: {
                        token: localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                });
                response = response.data;
                if (response.success) {
                    this.getAdDetails(this.state.ad_id);
                    this.Toast.fire({
                        icon: 'success',
                        title: 'Ad updated successfully'
                    }).finally(() => {
                        if(!this.state.assets) {
                            window.location.href = '/admin/ads';
                        } else {
                            window.location.reload();
                        }
                    });
                }
            } catch (e) {
                const error = e.response.data;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message
                });
            }
        } else {
            try {
                let response = await axios.post(server_url + '/ads', form_data, {
                    headers: {
                        token: localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                });
                response = response.data;
                if (response.success) {
                    this.Toast.fire({
                        icon: 'success',
                        title: 'Ad created successfully'
                    }).finally(() => {
                        window.location.href = '/admin/ads';
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.message
                    });
                }
            } catch (e) {
                const error = e.response.data;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message
                });
            }
        }
    }

    async deleteAsset(asset_id) {
        try {
            let response = await axios.delete(server_url + '/ads/' + this.state.ad_id + '/assets/' + asset_id, {
                headers: {
                    token: localStorage.getItem('token')
                }
            });
            response = response.data;
            if (response.success) {
                this.Toast.fire({
                    icon: 'success',
                    title: 'Asset deleted successfully'
                });
                await this.getAdDetails(this.state.ad_id);
            }
        } catch (e) {
            const error = e.response.data;
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.message
            });
        }
    }

    render() {
        return (
            <DashboardLayout>
                <MDBox position="relative" mb={5} mt={5}>

                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Ad
                                    </MDTypography>
                                </MDBox>
                                <MDBox
                                    pt={3}
                                    mx={3}
                                    mb={3}
                                >

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MDTypography fontWeight="regular">
                                                Ad Details
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <InputLabel id="category_select_label">Category<span>*</span></InputLabel>
                                            <Select
                                                labelId="category_select_label"
                                                id="category_select"
                                                value={this.state.category}
                                                label="Category"
                                                variant="standard"
                                                onChange={(e) => {
                                                    this.setState({ category: e.target.value });
                                                    this.getSubCategories(e.target.value);
                                                }}
                                                fullWidth
                                                size="large"
                                                style={{
                                                    // height: "30px",
                                                    color: "black"
                                                }}
                                                placeholder="Select Category"

                                            >
                                                {this.state.categories.map((category) => (
                                                    <MenuItem value={category.key} key={category.key}>{category.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <InputLabel id="sub_category_select_label">Sub Category<span>*</span></InputLabel>
                                            <Select
                                                labelId="sub_category_select_label"
                                                id="sub_category_select"
                                                value={this.state.sub_category}
                                                label="Sub Category"
                                                variant="standard"
                                                onChange={(e) => {
                                                    this.setState({ sub_category: e.target.value });
                                                }}
                                                fullWidth
                                                size="large"
                                                style={{
                                                    // height: "30px",
                                                    color: "black"
                                                }}
                                                placeholder="Select Sub Category"

                                            >
                                                {this.state.sub_categories.map((sub_category) => (
                                                    <MenuItem value={sub_category.key} key={sub_category.key}>{sub_category.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <InputLabel id="mode_label">Mode</InputLabel>
                                            <Select
                                                multiple
                                                labelId="mode_label"
                                                id="mode"
                                                value={this.state.mode}
                                                label="Mode"
                                                variant="standard"
                                                onChange={(e) => {
                                                    this.setState({ mode: e.target.value });
                                                }}
                                                fullWidth
                                                size="large"
                                                style={{
                                                    // height: "30px",
                                                    color: "black"
                                                }}
                                                placeholder="Select Mode"

                                            >
                                                {config.modes.map((mode) => (
                                                    <MenuItem value={mode.key} key={mode.key}>{mode.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <MDInput type="text" label="Title" fullWidth
                                                value={this.state.title}
                                                onChange={(e) => this.setState({ title: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                                            <MDInput type="text" label="Text" fullWidth
                                                value={this.state.text}
                                                onChange={(e) => this.setState({ text: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MDInput type="text" label="Additional Info" fullWidth multiline rows={4}
                                                value={this.state.additional_info}
                                                onChange={(e) => this.setState({ additional_info: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Price" fullWidth value={this.state.price}
                                                onChange={(e) => this.setState({ price: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDBox display="flex" alignItems="center">
                                                <MDBox>
                                                    <Switch checked={this.state.is_price_negotiable}
                                                        onChange={(e) => {
                                                            this.setState({ is_price_negotiable: e.target.checked });
                                                        }}
                                                    />
                                                </MDBox>
                                                <MDBox width="80%">
                                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                                        Price Negotiable
                                                    </MDTypography>
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MDTypography fontWeight="regular">
                                                Address Details
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Address Line 1" fullWidth value={this.state.address_line1}
                                                onChange={(e) => this.setState({ address_line1: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Address Line 2" fullWidth value={this.state.address_line2}
                                                onChange={(e) => this.setState({ address_line2: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Landmark" fullWidth value={this.state.landmark}
                                                onChange={(e) => this.setState({ landmark: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="City*" fullWidth value={this.state.city}
                                                onChange={(e) => this.setState({ city: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Taluk*" fullWidth value={this.state.taluk}
                                                onChange={(e) => this.setState({ taluk: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="District*" fullWidth value={this.state.district}
                                                onChange={(e) => this.setState({ district: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="State*" fullWidth value={this.state.state}
                                                onChange={(e) => this.setState({ state: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Country*" fullWidth value={this.state.country}
                                                onChange={(e) => this.setState({ country: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Pincode*" fullWidth value={this.state.pincode}
                                                onChange={(e) => this.setState({ pincode: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Latitude" fullWidth value={this.state.latitude}
                                                onChange={(e) => this.setState({ latitude: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Longitude" fullWidth value={this.state.longitude}
                                                onChange={(e) => this.setState({ longitude: e.target.value })}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MDTypography fontWeight="regular">
                                                Owner Details
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Owner Name" fullWidth value={this.state.owner_name}
                                                onChange={(e) => this.setState({ owner_name: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Phone Number" fullWidth value={this.state.phone_number}
                                                onChange={(e) => this.setState({ phone_number: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDInput type="text" label="Email ID" fullWidth value={this.state.email_id}
                                                onChange={(e) => this.setState({ email_id: e.target.value })}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MDTypography fontWeight="regular">
                                                Other Settings
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDBox display="flex" alignItems="center">
                                                <MDBox>
                                                    <Switch checked={this.state.is_verified}
                                                        onChange={(e) => {
                                                            this.setState({ is_verified: e.target.checked });
                                                        }}
                                                    />
                                                </MDBox>
                                                <MDBox width="80%">
                                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                                        Verified
                                                    </MDTypography>
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDBox display="flex" alignItems="center">
                                                <MDBox>
                                                    <Switch checked={this.state.is_featured}
                                                        onChange={(e) => {
                                                            this.setState({ is_featured: e.target.checked });
                                                        }}
                                                    />
                                                </MDBox>
                                                <MDBox width="80%">
                                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                                        Featured
                                                    </MDTypography>
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDBox display="flex" alignItems="center">
                                                <MDBox>
                                                    <Switch checked={this.state.status}
                                                        onChange={(e) => {
                                                            this.setState({ status: e.target.checked });
                                                        }}
                                                    />
                                                </MDBox>
                                                <MDBox width="80%">
                                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                                        Active
                                                    </MDTypography>
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MDTypography fontWeight="regular">
                                                Assets
                                            </MDTypography>
                                        </Grid>
                                        {(this.state.existing_assets && (Array.isArray(this.state.existing_assets) && this.state.existing_assets.length > 0)) && (
                                            <>
                                                {this.state.existing_assets.map((asset, index) => {
                                                    if (asset) {
                                                        return (
                                                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}
                                                                style={{ border: '1px solid #ccc', padding: '10px', margin: '10px', borderRadius: '10px' }}
                                                            >
                                                                <MDBox display="flex" alignItems="center">
                                                                    <MDBox>
                                                                        <img src={server_url + '/ads/' + this.state.ad_id + '/assets/' + asset} alt="asset" style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="error"
                                                                            onClick={async () => {
                                                                                let c = await Swal.fire({
                                                                                    title: 'Are you sure you want to delete image?',
                                                                                    text: "You won't be able to revert this!",
                                                                                    icon: 'warning',
                                                                                    showCancelButton: true,
                                                                                    confirmButtonColor: '#3085d6',
                                                                                    cancelButtonColor: '#d33',
                                                                                    confirmButtonText: 'Yes, delete it!'
                                                                                });
                                                                                if (c.isConfirmed) {
                                                                                    await this.deleteAsset(asset);
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        >
                                                                            Delete
                                                                        </MDButton>
                                                                    </MDBox>
                                                                </MDBox>
                                                            </Grid>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container spacing={1} style={{ marginTop: '10px' }}>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDBox display="flex" alignItems="center">
                                                <MDBox>
                                                    <input type="file" multiple accept="image/*" onChange={(e) => {
                                                        this.setState({ assets: e.target.files })
                                                    }} />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <MDTypography fontWeight="regular" fontSize="12px" color="error">
                                            Fields with * are mandetory
                                        </MDTypography>
                                    </Grid>
                                    <Grid container spacing={1} style={{ marginTop: '20px' }}>
                                        <Grid item xs={12} sm={12} md={3} lg={4} xl={4}></Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={() => {

                                                    this.saveAd();

                                                }}
                                                fullWidth
                                            >
                                                Save
                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </DashboardLayout>
        );
    }
}

export default CreateAd;