import axios from "axios"
import config from "../config";

const server_url = config.SERVER_URL;

const getSubCategories = async (category=null) => {
    let response;
    if (category) {
        response = await axios.get(`${server_url}/categories/${category}/sub_categories`);
    } else {
        response = await axios.get(`${server_url}/sub_categories`);
    }
    return response?.data?.sub_categories;
}

export {
    getSubCategories
}
