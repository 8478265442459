import axios from "axios"
import config from "../config";

const server_url = config.SERVER_URL;

const getStatistics = async (use_token = false) => {
    let response;
    if (use_token) {
        const token = localStorage.getItem("token");
        response = await axios.get(`${server_url}/statistics`, {
            headers: {
                token: token
            }
        });
    } else {
        response = await axios.get(`${server_url}/statistics`);
    }
    return response.data?.statistics;
}

export {
    getStatistics
}
