import React from "react";

// Grid Imports
import Grid from '@mui/material/Grid';

// Card Imports
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import config from "../config";
import VerifiedIcon from '@mui/icons-material/Verified';
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const server_url = config.SERVER_URL;


class DisplayCards extends React.Component {

    constructor(props) {
        super(props);
        this.ad = props.ad;
        this.state = {
            onHover: false
        }
    }

    goToCarddetails = (cardId) => {
        window.location.href='/adDisplay?id='+cardId;
    }

    render() {
        return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <Link to={'/adDisplay?id='+this.ad._id} style={{textDecoration: 'none'}}>
                <Card style={this.state.onHover ? style.cardHover : style.card}
                    onMouseEnter={() => this.setState({onHover: true})}
                    onMouseLeave={() => this.setState({onHover: false})}
                >
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                {this.ad?.owner_name?.[0]?.toUpperCase()}
                            </Avatar>
                        }
                        title={
                            <>
                                <b style={{fontSize: '20px', fontWeight: 600}}>{this.ad.title}</b>
                                {(this.ad?.is_verified) ? (
                                    <Tooltip title="Verified">
                                        <VerifiedIcon style={{color: 'green', marginLeft: '5px'}}/>
                                    </Tooltip>
                                ) : null}
                            </>
                        }
                        subheader={this.ad.city}
                    />
                    {this.ad.assets?.length > 0 ? (
                        <CardMedia
                            style={{borderRadius: '15px', textAlign: 'center', alignItems: 'center'}}
                            component="img"
                            height="350"
                            image={server_url+'/ads/'+this.ad._id+'/assets/'+this.ad.assets[0]}
                            alt={this.ad.assets[0]}
                        />
                    ) : (
                        <div
                            style={{borderRadius: '25px', height: '350px', textAlign: 'center'}}
                        >
                            <h2 style={{color: 'gray'}}>No Image Available</h2>
                        </div>
                    )}
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{fontSize: '20px', fontWeight: 600}}>{this.ad.owner_name}</div>
                                <div style={{fontSize: '20px', fontWeight: 600}}>₹ {this.ad.price}</div>
                            </div>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {this.ad.text}
                        </Typography>
                    </CardContent>
                </Card>
                </Link>
            </Grid>
        )
    }
}

const style = {
    card: {
        margin: '20px',
        borderRadius: '10px',
        cursor: 'pointer',
        transition: '0.2s',
        transform: 'scale(1)'
    },
    cardHover: {
        margin: '20px',
        borderRadius: '10px',
        cursor: 'pointer',
        boxShadow: '1px 1px 5px gray',
        transition: '0.2s',
        transform: 'scale(1.02)'
    }
}

export default DisplayCards;