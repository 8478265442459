/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React example components
import DashboardLayout from "./../../examples/LayoutContainers/DashboardLayout";


import React from "react";
import { Card, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { changePassword } from "services/auth.service";
import Swal from "sweetalert2";

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            current_password: '',
            new_password: '',
            confirm_password: '',
        };
    
    }

    componentDidMount() {

    }

    async changePassword() {
        let payload = {
            current_password: this.state.current_password,
            new_password: this.state.new_password,
            confirm_password: this.state.confirm_password,
        };

        let response = await changePassword(payload);
        if (response.success) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Password changed successfully',
            });
            this.setState({
                current_password: '',
                new_password: '',
                confirm_password: '',
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: response.message,
            });
        }
    }

    render() {
        return (
            <DashboardLayout>

                <h2>Settings</h2>

                <Grid container spacing={3} style={{marginTop: 5}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Card>
                            <CardHeader title="Change Password" />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={4} xl={4}>
                                        <TextField id="current-password" label="Current Password" variant="outlined" fullWidth
                                            type="password"
                                            value={this.state.current_password}
                                            onChange={(e) => this.setState({current_password: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={4} xl={4}>
                                        <TextField id="new-password" label="New Password" variant="outlined" fullWidth
                                            type="password"
                                            value={this.state.new_password}
                                            onChange={(e) => this.setState({new_password: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={4} xl={4}>
                                        <TextField id="confirm-password" label="Confirm Password" variant="outlined" fullWidth
                                            type="password"
                                            value={this.state.confirm_password}
                                            onChange={(e) => this.setState({confirm_password: e.target.value})}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} style={{marginTop: 10}}>
                                    <Grid item xs={12} md={12} lg={12} xl={12}>
                                        <MDButton variant="gradient" color="info"
                                            disabled={(this.state.current_password == '' ) || (this.state.new_password == '') || (this.state.confirm_password == '') || (this.state.new_password != this.state.confirm_password)}
                                            onClick={() => this.changePassword()}
                                        >
                                            Change Password
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DashboardLayout>
        );
    }
}
