import React from "react";

// Grid Imports
import Grid from '@mui/material/Grid';

// Component Imports
import DisplayCards from './displayCard';

// Service Imports
import axios from "axios";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Chip, Pagination, Stack } from "@mui/material";

import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Button from '@mui/material/Button';
import NoResultsFound from './../assets/images/NoResultsFound.png';

import Swal from 'sweetalert2';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
// import BagalkotBrokerLogo from './../assets/images/bagalkot-broker.jpg';
import Slider from '@mui/material/Slider';
import config from '../config';

import StatisticsCards from "components/StatisticsCards.component";
import Header from "components/Header.componenet";

const BagalkotBrokerLogo = require('./../assets/images/logo.png');

const server_url = config.SERVER_URL;


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ads: [],
            pages_count: 0,
            page: 0,
            limit: 10,
            skip: 0,
            total: 0,
            open: false,
            search: '',
            search_key: 'Title',
            search_keys: ["Title", "Description", "City", "Owner Name", "Owner Phone Number", "Owner Email"],
            slider_value: [4, 6],
            minPrice: '',
            maxPrice: '',
            tempMinPrice: '1000',
            tempMaxPrice: '100000',
            selected_categories: [],
            selected_sub_categories: [],
            selected_modes: []
        }
    }

    resetFilters = async () => {
        console.log('Reset Filter');
        await this.setState({
            minPrice: '',
            maxPrice: '',
            open: false
        });
        this.getAds();
        // this.setState({open: false});
    }

    applyFilters = async () => {
        await this.setState({open: false});
        await this.getAds();
    }

    handlePriceChange = (event, newValue) => {
        this.setState({
            price: newValue
        });
      };

    getAds = async (loading=true, limit=12, skip=0) => {
        try {

            if (loading) {
                // Swal loading
                Swal.fire({
                    title: 'Loading...',
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
            }

            let categories = this.state.selected_categories.join(',');
            let sub_categories = this.state.selected_sub_categories.join(',');
            let modes = this.state.selected_modes.join(',');

            let url = server_url + "/ads?limit=" + limit + "&skip=" + skip;
            if (this.state.search.length > 0) {
                url = url + '&searchvalue=' + this.state.search
            }
            if (this.state.minPrice.length > 0) {
                url = url + '&min_price=' + this.state.minPrice
            }
            if (this.state.maxPrice.length > 0) {
                url = url + '&max_price=' + this.state.maxPrice
            }
            if (categories.length > 0) {
                url = url + '&category=' + categories
            }
            if (sub_categories.length > 0) {
                url = url + '&sub_category=' + sub_categories
            }
            if (modes.length > 0) {
                url = url + '&mode=' + modes
            }
            const response = await axios.get(url);
            let ads = response.data.Ads;
            
            let total = response.data.total ? response.data.total : ads.length;
            let pages_count = Math.ceil(total / limit);
            let page = Math.ceil(skip / limit) + 1;

            this.setState({ ads: ads, total: total, pages_count: pages_count, page: page, limit: limit, skip: skip });

            if (loading) {
                // Swal Close
                Swal.close();
            }

        } catch (error) {
            console.log(error);
            // Swal error
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            });
        }
    };

    async componentDidMount() {


        await this.getAds();
        let counter = 1;
        setInterval(() => {
            // Change search key
            if (counter < this.state.search_keys.length) {
                this.setState({search_key: this.state.search_keys[counter]});
                counter++;
            } else {
                counter = 0;
            }
        }, 2000);

    }

    handleSliderChange = async (event, newValue, activeThumb) => {
        const min_distance = 1;
        if (!Array.isArray(newValue)) {
          return;
        }

        if (activeThumb === 0) {
            await this.setState({
                slider_value: [Math.min(newValue[0], this.state.slider_value[1] - min_distance), this.state.slider_value[1]],
                tempMinPrice: String(this.calculateValue(Math.min(newValue[0], this.state.slider_value[1] - min_distance)))
                // minPrice: String(this.calculateValue(Math.min(newValue[0], this.state.slider_value[1] - min_distance)))
            });
        } else {
            await this.setState({
                slider_value: [this.state.slider_value[0], Math.max(newValue[1], this.state.slider_value[0] + min_distance)],
                tempMaxPrice: String(this.calculateValue(Math.max(newValue[1], this.state.slider_value[0] + min_distance)))
                // maxPrice: String(this.calculateValue(Math.max(newValue[1], this.state.slider_value[0] + min_distance)))
            });
        }
    };

    calculateValue(value) {
        return (parseInt(10 ** value));
    }

    render() {
        return (
            <PageLayout background={config.background_color}>
                <header style={{marginBottom: '60px'}}>
                    <AppBar position="fixed" color="secondary">
                        <Header />
                    </AppBar>
                </header>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{backgroundColor: '#505050', textAlign: 'center', padding: '15px', borderTop: '3px dashed white'}}>
                            <Button
                                size="large"
                                color="white"
                                disabled
                                style={{color: 'white', fontSize: '20px', fontWeight: 'bold'}}
                            >
                                Place your Advertisement here
                            </Button>
                            <Button variant="outlined"
                                href="tel:+91 9739 800 522"
                                style={{color: 'white', fontSize: '20px', fontWeight: 'bold'}}
                            >
                                Call Now
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <StatisticsCards
                    // selected categories callback
                    onSelectedCategoriesChange={async (selected_categories) => {
                        await this.setState({
                            selected_categories: selected_categories
                        });
                        this.getAds(false);
                    }}
                    // selected subcategories callback
                    onSelectedSubCategoriesChange={async (selected_sub_categories) => {
                        await this.setState({
                            selected_sub_categories: selected_sub_categories
                        });
                        this.getAds(false);
                    }}
                    // selected modes callback
                    onSelectedModesChange={async (selected_modes) => {
                        await this.setState({
                            selected_modes: selected_modes
                        });
                        this.getAds(false);
                    }}
                />

                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display: 'flex', alignItems: 'end', justifyContent: "right"}}>
                        <Paper
                            sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '15px', height: '50px', borderRadius: '10px' }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1, fontSize: '20px', margin: '20px' }}
                                placeholder={this.state.search_key}
                                onChange={async (e) => {
                                    await this.setState({
                                        search: e.target.value
                                    })
                                    this.getAds(false);
                                }}
                                value={this.state.search}
                            />
                            <IconButton type="button" sx={{ p: '10px', fontSize: '30px', color: 'lightgray' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <Divider sx={{ height: '60px', m: 0.5, opacity: 1 }} orientation="vertical" />
                            <IconButton color="secondary" sx={{ p: '10px', fontSize: '30px', color: 'gray' }} aria-label="directions" 
                                onClick={() => {
                                    let toggleOpen = !this.state.open;
                                    this.setState({open: toggleOpen})
                                }}
                            >
                                <Tooltip title="Filter">
                                    <FilterAltIcon style={this.state.open ? {backgroundColor: 'gray', color: 'white', borderRadius: 50, padding: 5} : {backgroundColor: 'transparent', color: 'gray'}} />
                                </Tooltip>
                            </IconButton>
                        </Paper>
                    </Grid>
                    {(this.state.open) ? (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginLeft: '15px', marginRight: '15px', textAlign: 'center', alignItems: 'center'}}>
                            <Paper
                                sx={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', borderTop: '2px solid gray', textAlign: 'center', alignItems: 'center', padding: '20px' }}
                            >

                                <p>Filter by price</p>

                                <div style={{width: '100%'}}>
                                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        <>
                                            <p style={{fontSize: '20px'}}>₹</p>
                                        </>
                                        <Slider
                                            value={this.state.slider_value}
                                            onChange={this.handleSliderChange}
                                            valueLabelDisplay="always"
                                            // getAriaValueText={valuetext}
                                            disableSwap
                                            sx={{height: '10px'}}
                                            // sx={{marginTop: '30px', marginBottom: '30px'}}
                                            min={3}
                                            step={1}
                                            max={10}
                                            scale={this.calculateValue}
                                        />
                                        <>
                                            <p style={{fontSize: '40px'}}>₹</p>
                                        </>
                                    </Stack>
                                </div>

                                {this.state.tempMinPrice ? (
                                    <p>
                                        Min Price: ₹ {this.state.tempMinPrice}
                                    </p>
                                ) : null}

                                {this.state.tempMaxPrice ? (
                                    <p>
                                        Max Price: ₹ {this.state.tempMaxPrice}
                                    </p>
                                ) : null}


                                <div>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: config.primary_color,
                                            color: 'white',
                                            fontSize: '15px',
                                            borderRadius: '5px',
                                            padding: '10px',
                                            marginTop: '20px',
                                            marginBottom: '20px'
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                minPrice: this.state.tempMinPrice,
                                                maxPrice: this.state.tempMaxPrice,
                                                open: false
                                            })
                                            this.getAds();
                                        }}
                                    >
                                        Apply
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: config.secondary_color,
                                            color: 'white',
                                            fontSize: '15px',
                                            borderRadius: '5px',
                                            padding: '10px',
                                            marginTop: '20px',
                                            marginBottom: '20px',
                                            marginLeft: '20px'
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                minPrice: '',
                                                maxPrice: '',
                                                open: false
                                            })
                                            this.getAds();
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                    ) : null}

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{marginTop: '20px'}}>
                        <Stack direction="row" spacing={2} sx={{marginLeft: '20px'}}>
                            { this.state.minPrice.length > 0 && 
                                <Chip 
                                    label={"Min : ₹ " + this.state.minPrice}
                                    variant="outlined"
                                    onDelete={async () => {
                                        await this.setState({
                                            minPrice: ''
                                        })
                                        this.getAds();
                                    }}
                                />
                            }
                            { this.state.maxPrice.length > 0 && 
                                <Chip 
                                    label={"Max : ₹ " + this.state.maxPrice}
                                    variant="outlined"
                                    onDelete={async () => {
                                        await this.setState({
                                            maxPrice: ''
                                        })
                                        this.getAds();
                                    }}
                                />
                            }
                        </Stack>
                    </Grid>

                </Grid>
                <Grid container style={{width: '100%'}}>
                    {this.state.ads.map((x) => (
                        <DisplayCards ad={x} key={x._id}></DisplayCards>
                    ))}
                    {this.state.ads.length === 0 &&(
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'center', marginTop: '100px', marginBottom: '100px'}}>
                            <img src={NoResultsFound} alt="No Results Found" style={{height: '50vh', width: 'auto', borderRadius: '10px'}} />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" style={{width: '100%'}}>
                    <Grid item xs={12}>
                    <Pagination variant="outlined" shape="rounded" size="large" count={this.state.pages_count} page={this.state.page} onChange={(e, value) => {
                        this.setState({page: value});
                        this.getAds(true, this.state.limit, (value-1)*this.state.limit);
                    }} style={{margin: '10px'}} />
                    </Grid>
                </Grid>

                
                <FloatingWhatsApp
                    phoneNumber="919739800522"
                    accountName="Bagalkot Broker"
                    chatMessage="Hello, Send us a message if you want to place your Advertisement here."
                    darkMode={true}
                    avatar={BagalkotBrokerLogo}
                    buttonStyle={{backgroundColor: 'green'}}
                    notificationDelay={10}
                />

            </PageLayout>
        )
    }

}

export default Home;