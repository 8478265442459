import React, { Component } from 'react';
import { Card, CardActionArea, CardMedia } from '@mui/material';

// Images
import real_estate_image from './../assets/images/real_estate.jpg';
import land_image from './../assets/images/land.jpg';
import agricultural_land_image from './../assets/images/agricultural_land.jpg';
import apartment_image from './../assets/images/apartment.jpg';
import single_house_image from './../assets/images/single_house.jpg';
import rent_image from './../assets/images/rent.jpg';
import lease_image from './../assets/images/lease.jpg';
import buy_image from './../assets/images/buy.jpg';
import office_image from './../assets/images/office.jpg';
import shop_image from './../assets/images/shop.jpg';
import warehouse_image from './../assets/images/warehouse.jpg';
import factory_image from './../assets/images/factory.jpg';
import hotel_image from './../assets/images/hotel.jpg';
import villa_image from './../assets/images/villa.jpg';
import commercial_building_image from './../assets/images/commercial_building.jpg';
import commercial_land_image from './../assets/images/commercial_land.jpg';
import { getStatistics } from 'services/statistics.service';
import { getCategories } from 'services/category.service';
import { getSubCategories } from 'services/subCategory.service';
import config from 'config';


class StatisticsCards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            statistics: [],
            categories: [],
            sub_categories: [],
            selected_categories: [],
            selected_sub_categories: [],
            selected_modes: []
        }
    }

    async componentDidMount() {
        // get categories
        let categories = await getCategories();
        if (categories) {
            this.setState({ categories: categories });
        }

        let sub_categories = await getSubCategories();
        if (sub_categories) {
            this.setState({ sub_categories: sub_categories });
        }

        // get statistics
        let statistics = await getStatistics();
        if (statistics) {
            this.setState({ statistics: statistics });
        }
    }


    getImage = (name) => {
        switch (name) {
            case 'real_estate':
                return real_estate_image;
            case 'land':
                return land_image;
            case 'apartment':
                return apartment_image;
            case 'single_house':
                return single_house_image;
            case 'agricultural_land':
                return agricultural_land_image;
            case 'office':
                return office_image;
            case 'shop':
                return shop_image;
            case 'warehouse':
                return warehouse_image;
            case 'factory':
                return factory_image;
            case 'hotel':
                return hotel_image;
            case 'villa':
                return villa_image;
            case 'commercial_building':
                return commercial_building_image;
            case 'commercial_land':
                return commercial_land_image;
            case 'rent':
                return rent_image;
            case 'lease':
                return lease_image;
            case 'buy':
                return buy_image;
            default:
                return real_estate_image;
        }
    }

    getHumanReadableCategory = (category) => {
        let cat = this.state.categories.find((x) => x.key === category);
        if (cat) {
            return cat.name;
        }
        return category;
    }

    getHumanReadableSubCategory = (sub_category) => {
        let sub_cat = this.state.sub_categories.find((x) => x.key === sub_category);
        if (sub_cat) {
            return sub_cat.name;
        }
        return sub_category;
    }

    getHumanReadableMode = (mode) => {
        let mod = config.modes.find((x) => x.key === mode);
        if (mod) {
            return mod.name;
        }
        return mode;
    }


    render() {
        return (
            <div style={{width: '100%', overflow: 'auto', marginTop: 10}}>
                <div style={{display: 'flex', width: 'fit-content'}}>
                    {this.state.statistics?.category?.map((x) => (
                        <Card style={this.state.selected_categories.includes(x.category) ? styles.cardSelected : styles.card}>
                            <CardActionArea
                                onClick={async () => {
                                    if (this.state.selected_categories.includes(x.category)) {
                                        await this.setState({ selected_categories: this.state.selected_categories.filter((y) => y !== x.category) });
                                    } else {
                                        await this.setState({ selected_categories: [...this.state.selected_categories, x.category] });
                                    }
                                    if (this.props.onSelectedCategoriesChange) {
                                        this.props.onSelectedCategoriesChange(this.state.selected_categories);
                                    }
                                }}
                            >
                                <CardMedia component="img" height="140" image={this.getImage(x.category)} alt={x.category} style={{margin: 0, borderRadius: 10, width: '100%'}} />
                                <div style={styles.glassBackground}>
                                    <h2 style={{color: 'white'}}>{this.getHumanReadableCategory(x.category)}</h2>
                                    <h3 style={{color: 'white'}}>{x.count}</h3>
                                </div>
                            </CardActionArea>
                        </Card>
                    ))}

                    {this.state.statistics?.sub_category?.map((x) => (
                        <Card style={this.state.selected_sub_categories.includes(x.sub_category) ? styles.cardSelected : styles.card}>
                            <CardActionArea
                                onClick={async () => {
                                    if (this.state.selected_sub_categories.includes(x.sub_category)) {
                                        await this.setState({ selected_sub_categories: this.state.selected_sub_categories.filter((y) => y !== x.sub_category) });
                                    } else {
                                        await this.setState({ selected_sub_categories: [...this.state.selected_sub_categories, x.sub_category] });
                                    }
                                    if (this.props.onSelectedSubCategoriesChange) {
                                        this.props.onSelectedSubCategoriesChange(this.state.selected_sub_categories);
                                    }
                                }}
                            >
                                <CardMedia component="img" height="140" image={this.getImage(x.sub_category)} alt={x.sub_category} style={{margin: 0, borderRadius: 10, width: '100%'}} />
                                <div style={styles.glassBackground}>
                                    <h2 style={{color: 'white'}}>{this.getHumanReadableSubCategory(x.sub_category)}</h2>
                                    <h3 style={{color: 'white'}}>{x.count}</h3>
                                </div>
                            </CardActionArea>
                        </Card>
                    ))}

                    {this.state.statistics?.mode?.map((x) => (
                        <Card style={this.state.selected_modes.includes(x.mode) ? styles.cardSelected : styles.card}>
                            <CardActionArea
                                onClick={async () => {
                                    if (this.state.selected_modes.includes(x.mode)) {
                                        await this.setState({ selected_modes: this.state.selected_modes.filter((y) => y !== x.mode) });
                                    } else {
                                        await this.setState({ selected_modes: [...this.state.selected_modes, x.mode] });
                                    }
                                    if (this.props.onSelectedModesChange) {
                                        this.props.onSelectedModesChange(this.state.selected_modes);
                                    }
                                }}
                            >
                                <CardMedia component="img" height="140" image={this.getImage(x.mode)} alt={x.mode} style={{margin: 0, borderRadius: 10, width: '100%'}} />
                                <div style={styles.glassBackground}>
                                    <h2 style={{color: 'white'}}>{this.getHumanReadableMode(x.mode)}</h2>
                                    <h3 style={{color: 'white'}}>{x.count}</h3>
                                </div>
                            </CardActionArea>
                        </Card>
                    ))}
                </div>
            </div>
        );
    }
}

const styles = {
    glassBackground: {
        position: 'absolute',
        bottom: 10,
        background: 'rgba( 45, 45, 45, 0.55 )',
        boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
        backdropFilter: 'blur( 4px )',
        border: '1px solid rgba( 255, 255, 255, 0.18 )',
        width: '100%',
        padding: 10,
    },
    card: {
        width: '250px',
        margin: 10
    },
    cardSelected: {
        width: '250px',
        margin: 5,
        border: '3px solid darkblue',
        padding: 3,
        backgroundColor: 'transparent'
    }
}

export default StatisticsCards;