import axios from "axios"
import config from "../config";

const server_url = config.SERVER_URL;

const changePassword = async (payload) => {
    const token = localStorage.getItem("token");
    try {
        let response = await axios.post(`${server_url}/auth/change-password`, payload, {
            headers: {
                token: token
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }
}

export {
    changePassword
}
