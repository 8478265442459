import { Toolbar } from "@mui/material";
const BagalkotBrokerLogo = require('./../assets/images/logo.png');


const Header = () => {
    return (
        <Toolbar style={{backgroundColor: '#303030'}}>
            <img src={BagalkotBrokerLogo} alt="logo" style={{height: '50px', width: 'auto'}} />
        </Toolbar>
    )
}

export default Header;