import React from "react";

class Logout extends React.Component {
    async logout() {
        localStorage.removeItem("token");
        window.location.href = "/login";
    }

    componentDidMount() {
        this.logout();
    }

    render() {
        return (
            <div>
                <h1>Logout</h1>
                <button onClick={this.logout}>Logout</button>
            </div>
        );
    }
}

export default Logout;