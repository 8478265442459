/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "../components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import PageLayout from "../examples/LayoutContainers/PageLayout";
import bgImage from "../assets/images/bg-sign-in-basic.jpeg";
// import BasicLayout from "../layouts/authentication/components/BasicLayout";
import config from "../config";

const server_url = config.SERVER_URL;
const axios = require('axios').default;

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  async login() {
    const payload = {
      user_name: this.state.username,
      password: this.state.password
    };
    try {
      let response = await axios.post(server_url + '/login', payload);
      response = response.data;
      if (response.success) {
        localStorage.setItem('token', response.token);
        // this.props.history.push('/dashboard');
        window.location.href = '/dashboard';
      }
    } catch (e) {
      const error = e.response.data;
      alert(error.message);
    }
  }

  render() {
    return (
      <PageLayout>
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              bgImage &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <MDBox px={1} width="100%" height="100vh" mx="auto">
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    Sign in
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput type="email" label="Username" fullWidth
                        onChange={(e) => this.setState({ username: e.target.value })}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type="password" label="Password" fullWidth
                        onChange={(e) => this.setState({ password: e.target.value })}
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" fullWidth
                        onClick={async () => {
                          await this.login();
                        }}
                      >
                        sign in
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </PageLayout>
    );
  }
}

export default Login;