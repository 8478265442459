import React from "react";
import Carousel from 'react-material-ui-carousel'

import PageLayout from "examples/LayoutContainers/PageLayout";
import axios from "axios";

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, List, ListItem, ListItemAvatar } from '@mui/material';
import Chip from '@mui/material/Chip';
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CallIcon from '@mui/icons-material/Call';

// import Swal
import Swal from 'sweetalert2';

import config from '../config';
import Header from "components/Header.componenet";

// Component Imports

const server_url = config.SERVER_URL;
class AdDisplay extends React.Component {

    items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!"
        },
        {
            name: "Random Name #2",
            description: "Hello World!"
        }
    ]

    constructor(props) {
        super(props);

        this.state = {
            categories: '',
            sub_categories: '',
            _id: null,
            existing_assets: '',
            category: '',
            sub_category: '',
            mode: [],
            title: '',
            text: '',
            additional_info: '',
            price: '',
            is_price_negotiable: false,
            address_line1: '',
            address_line2: '',
            landmark: '',
            city: '',
            taluk: '',
            district: '',
            state: '',
            country: '',
            pincode: '',
            latitude: '',
            longitude: '',
            owner_name: '',
            phone_number: '',
            email_id: '',
            is_verified: false,
            is_featured: false,
            status: true,
            assets: []
        };
    }

    async componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');

        await this.getAdDetails(id);
    }

    getAdDetails = async (id) => {
        try {

            // Swal loading
            Swal.fire({
                title: 'Loading...',
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            const response = await axios.get(server_url + "/Ads/" + id);

            const adDetails = response.data.message;

            this.setState({
                _id: adDetails._id,
                category: adDetails.category,
                sub_category: adDetails.sub_category_key,
                mode: adDetails.mode,
                title: adDetails.title,
                text: adDetails.text,
                additional_info: adDetails.additional_info,
                price: adDetails.price,
                is_price_negotiable: adDetails.is_price_negotiable,
                address_line1: adDetails.address_line1,
                address_line2: adDetails.address_line2,
                landmark: adDetails.landmark,
                city: adDetails.city,
                taluk: adDetails.taluk,
                district: adDetails.district,
                state: adDetails.state,
                country: adDetails.country,
                pincode: adDetails.pincode,
                latitude: adDetails.latitude,
                longitude: adDetails.longitude,
                owner_name: adDetails.owner_name,
                phone_number: adDetails.phone_number,
                email_id: adDetails.email_id,
                is_verified: adDetails.is_verified,
                is_featured: adDetails.is_featured,
                status: (adDetails.status === 'active') ? true : false,
                assets: adDetails.assets
            })

            // Swal close
            Swal.close();

        } catch (error) {
            console.error(error.message);
            // Swal Error
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            });
        }
    };

    render() {
        return (
            <PageLayout background={config.background_color}>
                <header style={{ marginBottom: '80px' }}>
                    <AppBar position="fixed" color="secondary">
                        <Header />
                    </AppBar>
                </header>
                <Card style={{ padding: '15px', borderRadius: '10px', margin: 10 }}>
                    <Grid container style={{width: '100%'}} spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={9} xl={9} style={{width: '100%'}}>
                            <h2>
                                {(this.state.title).replace(/\b\w/g, (c) => c?.toUpperCase())}
                                {this.state.is_verified && (
                                    <Tooltip title="Verified">
                                        <VerifiedIcon style={{color: 'green', marginLeft: '5px'}}/>
                                    </Tooltip>
                                )}
                            </h2>
                            <Typography variant="h4" component="p" style={{ marginTop: '20px', marginBottom: '5px' }}>
                                <span style={{ fontWeight: '800' }}>{this.state.text}</span>
                            </Typography>
                            <Typography variant="body2" component="p" style={{textAlign: 'justify'}}>
                                <span>{this.state.additional_info}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} xl={3} style={{display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 10}}>
                            <span style={{textAlign: 'right', alignItems: 'right', width: '100%'}}>
                                <Typography variant="h2" component="h2" style={{textAlign: 'center'}}>
                                    ₹ {this.state.price}/-
                                </Typography>
                                <div style={{textAlign: 'center', marginTop: 20}}>
                                    {this.state.mode.map(m => (
                                        <Chip
                                            size="medium"
                                            label={m?.toUpperCase()}
                                            variant="outlined"
                                            color="secondary"
                                            style={{
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                borderRadius: '5px',
                                                borderColor: config.primary_color,
                                                color: config.primary_color,
                                                fontWeight: '600'
                                            }}
                                        />
                                    ))}
                                </div>
                            </span>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '30px' }}>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
                            <Carousel
                                navButtonsAlwaysVisible='true'
                                NextIcon={
                                    <ArrowForwardIosIcon style={{ height: '100%' }} />
                                }
                                PrevIcon={
                                    <ArrowBackIosNewIcon style={{ height: '100%' }} />
                                }
                                animation="slide"
                                duration={1000}
                                indicators={false}
                            >
                                {
                                    this.state.assets.map((item, i) =>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CardActionArea>
                                                <div style={{ width: '100%', textAlign: 'center', height: '350px' }}>
                                                    <img
                                                        src={server_url + '/ads/' + this.state._id + '/assets/' + this.state.assets[i]}
                                                        alt={this.state.assets[i]}
                                                        style={{
                                                            textAlign: 'center',
                                                            maxWidth: '80%',
                                                            maxHeight: '100%'
                                                        }}
                                                    />
                                                </div>
                                            </CardActionArea>
                                        </div>
                                    )
                                }
                            </Carousel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>


                            <Grid container>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                    <List>
                                        <ListItem style={{marginBottom: 20}}>
                                            <ListItemAvatar>
                                                <Avatar style={this.state.owner_name ? {backgroundColor: config.primary_color} : {}}>
                                                    <AccountCircleIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                style={{marginLeft: '20px'}}
                                                primary={
                                                    this.state.owner_name ? 
                                                        this.state.owner_name :
                                                        "Not available"
                                                }
                                            />
                                        </ListItem>
                                        <ListItem style={{marginBottom: 20}}>
                                            <ListItemAvatar>
                                                <Avatar style={this.state.phone_number ? {backgroundColor: config.primary_color} : {}}>
                                                    <PhoneIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                style={{marginLeft: '20px'}}
                                                primary={
                                                    this.state.phone_number ? 
                                                        this.state.phone_number :
                                                        "Not available"
                                                }
                                            />
                                            <Button
                                                variant="outlined"
                                                startIcon={<CallIcon />}
                                                style={{
                                                    color: config.primary_color,
                                                    borderColor: config.primary_color
                                                }}
                                            >
                                                Call
                                            </Button>
                                        </ListItem>
                                        <ListItem style={{marginBottom: 20}}>
                                            <ListItemAvatar>
                                                <Avatar style={this.state.email_id ? {backgroundColor: config.primary_color} : {}}>
                                                    <EmailIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                style={{marginLeft: '20px'}}
                                                primary={
                                                    this.state.email_id ? 
                                                        this.state.email_id :
                                                        "Not available"
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>


                            </Grid>


                            <hr style={{marginTop: 20, marginBottom: 20}} />
                            
                            <div style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '20px' }} align="center" justify="center" alignItems="center">
                                <Typography color="textSecondary">
                                    Address
                                </Typography>
                                <Typography variant="h5" component="h2">
                                {this.state.address_line1}{', '}{this.state.address_line2}{', '}{this.state.city}{' - '}{this.state.district}
                                </Typography>
                                { this.state.landmark && 
                                    <Typography variant="body2" component="p">
                                        Land mark - <span style={{ fontWeight: '500' }}>{this.state.landmark}</span>
                                    </Typography>
                                }
                                
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{margin: 20}}>
                            {this.state.latitude && this.state.longitude && (
                                <div
                                    style={{height: '400px', width: '100%'}}
                                    onClick={() => {
                                        window.open(`https://maps.google.com/maps?q=${this.state.latitude},${this.state.longitude}`, '_blank');
                                    }}
                                >
                                    <iframe
                                        title="map"
                                        width="100%"
                                        height="100%"
                                        frameborder="0"
                                        marginheight="0"
                                        marginwidth="0"
                                        src={`https://maps.google.com/maps?width=600&height=800&l=en&q=${this.state.latitude},${this.state.longitude}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
                                    ></iframe>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Card>
            </PageLayout >
        )
    }
}

export default AdDisplay;